ul#mainTabs {
    a.nav-link.active > div{
        border-bottom: 2px solid white;
        font-weight: bold;
    }
}


li.page-item.disabled {
    cursor: not-allowed;
}
